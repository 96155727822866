import { INFINITE_NUMBER } from 'constants/numbers';
import { featuresAdapter, getToolsFromFeaturesAndPlans } from 'state/plans/basePlansAdapter';
import { servicesSubPlanAdapter } from 'state/plans/servicesPlanAdapter';
import { promoSubPlanAdapter } from 'state/plans/promoPlanAdapter';
import {
    CategoryPlanResponseType,
    SubPlanResponseType,
    PlansResponseType,
    LastExpiredTrialResponseType,
} from './PlansResponseType';
import { CategoryPlanType, PlansType, SubPlanType } from './PlansType';

const subPlanAdapter = (plans: SubPlanResponseType[], { isReviewAso = false, isReviews = false } = {}): SubPlanType[] =>
    plans.map(
        ({
            features,
            reviews_features,
            review_aso_id,
            paired_reviews_plans,
            pricing,
            next_plan,
            display_name,
            name,
            ...planProps
        }) => ({
            name: display_name,
            reviewsFeatures: featuresAdapter(reviews_features),
            features: featuresAdapter(features),
            tools: getToolsFromFeaturesAndPlans(isReviews ? featuresAdapter(reviews_features) : featuresAdapter(features)),
            ...(review_aso_id ? { reviewAsoId: review_aso_id } : {}),
            ...(paired_reviews_plans ? { pairedReviewsPlansIds: paired_reviews_plans } : {}),
            pricing: pricing.map(
                ({
                    price_saving,
                    duration_months,
                    total_price,
                    total_price_discount,
                    price,
                    price_difference,
                    price_difference_rub,
                    ...priceProps
                }) => ({
                    priceSaving: price_saving,
                    durationMonths: duration_months,
                    totalPrice: total_price,
                    totalPriceDiscount: total_price_discount,
                    price: +price,
                    priceDifference: price_difference,
                    priceDifferenceRub: price_difference_rub,
                    ...priceProps,
                })
            ),
            isReviewAso,
            nextPlanId: next_plan,
            ...planProps,
        })
    );

const categoryPlansAdapter = (
    categoryPlansData: CategoryPlanResponseType[],
    { isReviewAso = false, isReviews = false } = {}
): CategoryPlanType[] =>
    categoryPlansData
        .filter(({ plans }) => plans.length)
        .map(({ is_best_plan, plans, description, ...categoryPlanProps }) => ({
            isBestPlan: is_best_plan,
            plans: subPlanAdapter(plans, { isReviewAso, isReviews }),
            ...(description ? { description } : {}),
            ...categoryPlanProps,
        }));

const lastExpiredTrialAdapter = ({
    id,
    name,
    keyword_limit,
    replies_limit,
    teammates_limit,
    replies_count,
    teammates_count,
    keyword_count,
    daily_keyword_stats_count,
    daily_keyword_stats_limit,
    app_limit,
    app_count,
    plan_id,
    order,
}: LastExpiredTrialResponseType) => ({
    id,
    name,
    keywordLimit: keyword_limit,
    keywordCount: keyword_count,
    dailyKeywordStatsCount: daily_keyword_stats_count,
    dailyKeywordStatsLimit: daily_keyword_stats_limit,
    repliesLimit: replies_limit,
    repliesCount: replies_count,
    teammatesCount: teammates_count,
    teammatesLimit: teammates_limit,
    appLimit: app_limit,
    appCount: app_count,
    planId: plan_id,
    order,
});

export const plansAdapter = ({
    can_trial,
    current: {
        id,
        name,
        display_name,
        price,
        start,
        period,
        category,
        finish,
        app_limit,
        app_count,
        keyword_count,
        keyword_limit,
        daily_keyword_stats_count,
        daily_keyword_stats_limit,
        replies_count,
        replies_limit,
        teammates_count,
        teammates_limit,
        plan_id,
        order,
    },
    reviews,
    aso,
    services,
    specials,
    reviews_aso,
    enterprise_plan = {},
    last_expired_trial,
}: PlansResponseType): PlansType => {
    const { features = [], additional_features = [], customer_support_features = [] } = enterprise_plan;

    return {
        isTrial: can_trial,
        currentPlan: {
            id,
            name: display_name === 'Basic' ? 'Free' : display_name,
            price,
            start,
            period,
            category,
            finish,
            order,
            coupon: '',
            appLimit: app_limit === 'inf' ? INFINITE_NUMBER : app_limit || undefined,
            appCount: app_count,
            keywordCount: keyword_count,
            keywordLimit: keyword_limit === 'inf' ? INFINITE_NUMBER : keyword_limit,
            dailyKeywordStatsCount: daily_keyword_stats_count,
            dailyKeywordStatsLimit: daily_keyword_stats_limit === 'inf' ? INFINITE_NUMBER : daily_keyword_stats_limit,
            repliesCount: replies_count,
            repliesLimit: replies_limit,
            teammatesCount: teammates_count,
            teammatesLimit: teammates_limit,
            planId: plan_id,
        },
        lastExpiredTrial: last_expired_trial ? lastExpiredTrialAdapter(last_expired_trial) : undefined,
        reviews: categoryPlansAdapter(reviews, { isReviews: true }),
        aso: categoryPlansAdapter(aso),
        reviewsAso: categoryPlansAdapter(reviews_aso, { isReviewAso: true }),
        services: servicesSubPlanAdapter(services),
        promo: promoSubPlanAdapter(specials),
        enterprisePlan: {
            features,
            additionalFeatures: additional_features,
            customerSupportFeatures: customer_support_features,
        },
    };
};
