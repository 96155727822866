import React, { FC, useState, useEffect } from 'react';
import { PaymentPeriodEnum, SubscriptionTypeEnum } from 'state/payment/paymentTypes';
import { CategoryPlanType } from 'state/plans/PlansType';
import { PlanCard, PlanCardStateEnum } from 'newComponents/plans/planCard/PlanCard';
import { PlanFeatures } from 'newComponents/plans/planFeatures/PlanFeatures';
import { withSpecificPlural } from 'utils/helpers/string/text';
import { numberFormat } from 'utils/helpers/string/numberFormat';
import { LANGUAGE_RU } from 'constants/localizationsConstants';
import { useActionPropsForPlans } from 'modules/settings/plans/planTabs/useActionPropsForPlans';
import { PlanTabsActionType } from 'modules/settings/plans/planTabs/planTabsTypes';
import { usePlanTabs } from 'modules/settings/plans/planTabs/usePlanTabs';
import { getCurrencySymbol } from 'modules/modal/paymentModal/constants';
import { SelectPlanReviewsContainer } from './SelectPlanReviewsContainer';

type PropsType = CategoryPlanType & {
    paymentPeriod: PaymentPeriodEnum;
    buttonPortal?: React.RefObject<HTMLDivElement>;
    action?: PlanTabsActionType;
    hideDescription?: boolean;
    onChangePlanId?: (planId: number) => void;
    badgeText?: string;
    state?: PlanCardStateEnum;
    needSalesManager?: boolean;
    businessActions?: {
        text: string;
        onClick: () => void;
    };
    isShowActions?: boolean;
    isStickyControls?: boolean;
};

export const ReviewsPlanCardContainer: FC<PropsType> = ({
    name,
    description,
    isBestPlan,
    plans,
    paymentPeriod,
    buttonPortal,
    action,
    hideDescription = false,
    onChangePlanId,
    badgeText,
    state,
    isShowActions = true,
    needSalesManager,
    isStickyControls,
}: PropsType) => {
    const [planId, setPlanId] = useState(plans[0].id);

    const { statePlanCard, planCardPeriod } = usePlanTabs({ state, isBestPlan, paymentPeriod });

    const currentPlan = plans.find(({ id }) => id === planId);
    const currentPrice = currentPlan?.pricing.find(({ id }) => id === paymentPeriod);

    useEffect(() => {
        if (onChangePlanId) {
            onChangePlanId(planId);
        }
    }, [planId]);

    if (!currentPlan || !currentPrice) return null;

    const selectedReview = {
        id: currentPlan.id,
        value: `${numberFormat({ number: currentPlan.reviews, locale: LANGUAGE_RU })} ${withSpecificPlural(
            currentPlan.reviews,
            'reply',
            'replies'
        )}`,
        subValue: '/month',
    };

    const isDiscount = currentPrice.priceSaving > currentPrice.price;
    const features = hideDescription
        ? currentPlan.reviewsFeatures.filter(({ title }) => title === 'Limits')
        : currentPlan.reviewsFeatures;

    return (
        <PlanCard
            hideDescription={hideDescription}
            title={name}
            description={description}
            {...(isBestPlan ? { badgeText: 'Best value' } : {})}
            {...(badgeText ? { badgeText } : {})}
            price={`${getCurrencySymbol()}${numberFormat({ number: currentPrice.price })}`}
            period={planCardPeriod}
            {...(isDiscount ? { oldPrice: `${getCurrencySymbol()}${numberFormat({ number: currentPrice.priceSaving })}` } : {})}
            {...(currentPrice.totalPriceDiscount
                ? { savingPrice: `${getCurrencySymbol()}${numberFormat({ number: currentPrice.totalPriceDiscount })}` }
                : {})}
            totalPrice={needSalesManager ? '' : `${getCurrencySymbol()}${numberFormat({ number: currentPrice.totalPrice })}`}
            buttonDescription={needSalesManager ? 'Check prices with manager' : ''}
            state={statePlanCard}
            TopControls={
                <SelectPlanReviewsContainer
                    plans={plans}
                    setPlanId={setPlanId}
                    selectedReview={selectedReview}
                    paymentPeriod={paymentPeriod}
                />
            }
            Content={<PlanFeatures features={features} />}
            {...(isShowActions
                ? {
                      action: {
                          ...action,
                          onClick: () => {
                              action?.onClick({
                                  subscriptionType: SubscriptionTypeEnum.SUBSCRIPTIONS,
                                  paymentPeriod,
                                  plan: currentPlan,
                              });
                          },
                      },
                  }
                : {})}
            buttonPortal={buttonPortal}
            isStickyControls={isStickyControls}
        />
    );
};

export const ReviewsPlanCardWithPaymentModalActionContainer = (props: Omit<PropsType, 'action'>) => {
    const { actionProps } = useActionPropsForPlans(true);

    const { name, paymentPeriod } = props;

    return <ReviewsPlanCardContainer action={actionProps} {...props} name={name} paymentPeriod={paymentPeriod} />;
};
