import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPlansData } from 'state/plans/controller';
import { usePlansGetter } from 'hooks/data/usePlansGetter';
import { SubPlanType } from 'state/plans/PlansType';

export const usePlans = (needReloadData = false) => {
    const { isTrial, currentPlan, aso, reviews, reviewsAso, services, promo } = usePlansGetter();
    const dispatch = useDispatch();

    const [isLoadingPlansData, setIsLoadingPlansData] = useState(true);

    useEffect(() => {
        const onLoadPlansData = async () => {
            try {
                setIsLoadingPlansData(true);
                await dispatch(getPlansData());
            } finally {
                setIsLoadingPlansData(false);
            }
        };

        if (!currentPlan || needReloadData) {
            onLoadPlansData();
        } else {
            setIsLoadingPlansData(false);
        }
    }, []);

    const asoPlans = aso.reduce((result: SubPlanType[], asoPlan) => [...result, ...asoPlan.plans], []);

    const reviewsAsoPlans = reviewsAso.reduce(
        (result: SubPlanType[], reviewsAsoPlan) => [...result, ...reviewsAsoPlan.plans],
        []
    );

    const getCurrentPlan = ({ currentPlanId, currentPlanOrder }: { currentPlanId?: number; currentPlanOrder?: number }) => {
        const allPlans = [...asoPlans, ...reviewsAsoPlans];

        const currentBasePlan = allPlans.find(({ nextPlanId, id }) => nextPlanId === currentPlanId || id === currentPlanId);
        let hasASOReview = false;

        if (reviewsAsoPlans.find(({ id }) => id === currentBasePlan?.id)) {
            hasASOReview = true;
        }

        const basePlan = allPlans.find(({ id }) => id === currentBasePlan?.base);

        return {
            currentPlan: {
                id: (hasASOReview ? basePlan?.id : currentBasePlan?.id) || currentPlanId,
                order: (hasASOReview ? basePlan?.order : currentBasePlan?.order) || currentPlanOrder,
            },
            currentBasePlan,
        };
    };

    return {
        isLoadingPlansData,
        setIsLoadingPlansData,
        isTrial,
        currentPlan,
        aso,
        reviews,
        reviewsAso,
        services,
        promo,
        reviewsAsoPlans,
        getCurrentPlan,
    };
};
