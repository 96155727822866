import { AppsRequestParamsType } from 'state/AppsRequestParamsType';
import { host } from 'constants/host';
import { CustomReportChannelType } from 'state/customReports/CustomReportsListType';

export enum ZendeskUrlsEnum {
    getList = 'zendesk:api:internal:api_integration:settings:list',
    create = 'zendesk:api:internal:apiIntegration:settings:create',
    manage = 'zendesk:api:internal:api_integration:settings:manage',
    applicationManage = 'zendesk:api:internal:apiIntegration:settings:applicationManage',
    groupManage = 'zendesk:api:internal:apiIntegration:externalPanel:groupManage',
}

export enum OmnideskUrlsEnum {
    getList = 'omnidesk:api:settings:list',
    create = 'omnidesk:api:settings:create',
    manage = 'omnidesk:api:settings:manage',
    applicationManage = 'omnidesk:api:settings:application_manage',
    groupManage = 'omnidesk:api:external_panel:group_manage',
}

export enum UsedeskUrlsEnum {
    getList = 'usedesk:api:settings:list',
    create = 'usedesk:api:settings:create',
    manage = 'usedesk:api:settings:manage',
    applicationManage = 'usedesk:api:settings:application_manage',
    groupManage = 'usedesk:api:external_panel:agent_list',
}

export enum CustomReportsEnum {
    getList = 'customReports:api:channel:list',
    getReportHistory = 'customReports:api:channel:reportHistory',
    createReport = 'custom_reports:api:channel:report:create',
    updateReport = 'custom_reports:api:channel:report:manage',
}

export const Urls = window.Urls || {
    auth_logout: () => `${host}/api/auth/logout`,
    unsubscribeLink: (userName: string, id: string) => `/unsubscribe/${userName}/${id}`,
    activity_log: () => `${host}/settings/activity_log`,
    ui_config: () => `${host}/api/frontend-settings/ui-config/`,
    revert_logentry: (id: number) => `${host}/settings/revert_logentry/${id}`,
    app: (country: string, appId: string | number) => `/${country}/app/${appId}`,
    app_favorite_keywords: (country: string, appId: string | number) => `/${country}/app/${appId}/favorite-keywords`,
    keyword_analysis_keyword_highlights: (appId: string | number) => `${host}/api/${appId}/keyword-analysis/keyword-highlights/`,
    keyword_analysis_keyword_highlights_export: (appId: string | number) =>
        `${host}/api/${appId}/keyword-analysis/keyword-highlights/export`,
    keyword_analysis_keyword_highlights_count: (appId: string | number) =>
        `${host}/api/${appId}/keyword-analysis/keyword-highlights/count/`,
    keyword_analysis_keyword_favorite: (appId: string | number) => `${host}/api/${appId}/keyword-analysis/keyword-favorite/`,
    keyword_analysis_keyword_favorite_export: (appId: string | number) =>
        `${host}/api/${appId}/keyword-analysis/keyword-favorite/export/`,
    keyword_analysis_keyword_favorite_count: (appId: string | number) =>
        `${host}/api/${appId}/keyword-analysis/keyword-favorite/count/`,
    rating_analysis_country_breakdown: (appId: string | number) => `${host}/api/${appId}/rating-analysis/country-breakdown/`,
    rating_analysis_version_breakdown: (appId: string | number) => `${host}/api/${appId}/rating-analysis/version-breakdown/`,
    ra_country_breakdown: (appId: string | number) => `${host}/api/${appId}/reviews-analysis/country-breakdown/`,
    ra_language_breakdown: (appId: string | number) => `${host}/api/${appId}/reviews-analysis/language-breakdown/`,
    ra_version_breakdown: (appId: string | number) => `${host}/api/${appId}/reviews-analysis/version-breakdown/`,
    app_featured_reviews: (country: string, appId: string) => `/${country}/app/${appId}/featured-reviews`,
    set_keyword_color: (country: string, appId: string) => `${host}/api/${country}/${appId}/set-keyword-color`,
    remove_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/remove-keywords`,
    update_keyword: (country: string, appId: string, keywordIds: number[] | string[]) =>
        `${host}/api/${country}/${appId}/keyword/${keywordIds.join(',')}/update`,
    update_applocale_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/update-all-keywords`,
    get_suggestions: (country: string, appId: string, keywordId: number) =>
        `${host}/api/${country}/${appId}/keyword/${keywordId}/suggestions`,
    add_suggested_keyword: (country: string, appId: string) => `${host}/api/${country}/${appId}/add-suggested-keyword`,
    get_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/get-keywords`,
    api_new_campaign: (country: string, appId: string) => `${host}/api/${country}/${appId}/new-campaign`,
    api_app_keyword_chart: (country: string, appId: string, keywordId: string) =>
        `${host}/api/${country}/${appId}/keyword/${keywordId}/chart`,
    track_app: (country: string, trackStoreId: string) => `${host}/api/${country}/${trackStoreId}/track-app`,
    api_app_add_competitor: (country: string, appId: string) => `${host}/api/${country}/${appId}/add-competitor`,
    api_app_top_apps: (country?: string, appId?: string, keywordId?: number) =>
        `${host}/api/${country}/${appId}/keyword/${keywordId}/top-apps`,
    app_xlsx_report: (country: string, appId: string) => `${host}/${country}/app/${appId}/report`,
    add_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/add-keywords`,
    tracked_apps: () => `${host}/api/tracked-apps`,
    api_keyword_shuffler: (country: string, appId: string) => `${host}/api/${country}/${appId}/shuffle`,
    api_keyword_shuffler_free: (country: string, appId: string) => `${host}/api/${country}/${appId}/shuffle/free`,
    search_competitor: (country: string, appId: string) => `${host}/api/${country}/${appId}/search-competitor`,
    countries_languages: () => `${host}/api/countries-languages`,
    localizations: () => `${host}/api/countries-localizations`,
    api_app_remove_competitor: (country: string, appId: string) => `${host}/api/${country}/${appId}/remove-competitor`,
    api_app_versions: (appId: string) => `${host}/api/${appId}/versions`,
    api_aso_dashboard: (appId: string) => `${host}/api/${appId}/dashboard`,
    api_app_comparative_report_version: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/comparative-report-versions`,
    api_app_comparative_report_chart: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/comparative-report-chart`,
    request_info: () => `${host}/api/request-info`,
    keyword_analytics_suggestions: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/keyword-analytics/suggestions`,
    api_locales_list: (country: string, appId: string) => `${host}/api/${country}/${appId}/locales-list`,
    api_custom_traffic_score: (country: string, appId: string, keywordId: number) =>
        `${host}/api/${country}/${appId}/keyword/${keywordId}/update-custom-traffic`,
    keyword_density_counter: () => '/keyword-density-counter',
    api_keyword_density_counter: () => `${host}/api/density-counter`,
    api_keyword_density_counter_export: () => '/api/density-counter/export',
    api_app_keyword_density_counter: (country: string, appId: string) => `${host}/api/${country}/${appId}/density-counter`,
    app_store_connection_provider_list: () => `${host}/api/app-store-connect-provider/`,
    app_store_connection_provider_phone_verify: (appId: string) =>
        `${host}/api/app-store-connect-provider/${appId}/phone-verify/`,
    app_store_connection_provider_send_secret_code: (appId: string) =>
        `${host}/api/app-store-connect-provider/${appId}/send-secret-code/`,
    app_store_connection_provider_update_session: (appId: string) =>
        `${host}/api/app-store-connect-provider/${appId}/update-session/`,
    opt_index: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/`,
    opt_meta_export: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/meta/export/`,
    opt_reset: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/reset/`,
    opt_sets_manage: (country: string, appId: string, setId: number) =>
        `${host}/api/${country}/${appId}/optimizer/sets/${setId}/`,
    opt_sets_create: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/sets/create/`,
    opt_reports_sqr: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/reports/sqr/`,
    opt_reports_kr: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/reports/kr/`,
    opt_reports_sr: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/reports/sr/`,
    opt_reports_summary: (country: string, appId: string) => `${host}/api/${country}/${appId}/optimizer/reports/summary/`,
    opt_reports_sqr_export: (country: string, appId: string, xlsx: string) =>
        `${host}/api/${country}/${appId}/optimizer/reports/sqr/${xlsx}/`,
    opt_reports_kr_export: (country: string, appId: string, xlsx: string) =>
        `${host}/api/${country}/${appId}/optimizer/reports/kr/${xlsx}/`,
    opt_reports_sr_export: (country: string, appId: string, xlsx: string) =>
        `${host}/api/${country}/${appId}/optimizer/reports/sr/${xlsx}/`,
    // TABs
    tabs_list: (country: string, appId: string, tabId?: number) =>
        `${host}/api/${country}/${appId}/keyword-analytics/tabs/${tabId ? `${tabId}/` : ''}`,
    tabs_move_keywords: (country: string, appId: string, tabId: number) =>
        `${host}/api/${country}/${appId}/keyword-analytics/tabs/${tabId}/move_keywords`,
    tabs_remove_keywords: (country: string, appId: string, tabId: number) =>
        `${host}/api/${country}/${appId}/keyword-analytics/tabs/${tabId}/remove_keywords`,
    set_keyword_favorite: (country: string, appId: string) => `${host}/api/${country}/${appId}/set-keyword-favorite`,
    set_keyword_note: (country: string, appId: string) => `${host}/api/${country}/${appId}/set-keyword-note`,
    add_top_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/add-top-keywords`,
    api_keyword_rank_history: (country: string, appId: string) => `${host}/api/${country}/${appId}/rank-history`,
    tabs_detail: (country: string, appId: string, tabId: number) =>
        `${host}/api/${country}/${appId}/keyword-analytics/tabs/${tabId}/`,
    keyword_analytics_suggestions_competitor_keywords: (country: string, appId: string, competitorId: string) =>
        `${host}/api/${country}/${appId}/keyword-analytics/suggestions/competitor-keywords/${competitorId}`, // this
    keyword_analytics_searchads_suggestions: (country: string, appId: string, competitorId: string) =>
        `${host}/api/${country}/${appId}/keyword-analytics/suggestions/sa/${competitorId}`,
    keyword_shuffler: () => '/keyword-shuffler',
    split_text_into_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/textanalyze/`,
    api_aso_dashboard_countries: (appId: string) => `${host}/api/${appId}/dashboard/countries`,
    api_aso_dashboard_presets: (appId: string) => `${host}/api/${appId}/dashboard/presets`,
    api_aso_dashboard_export: (appId: string) => `${host}/api/${appId}/dashboard/export`,
    kas_mp_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/kas/most-popular-keywords`,
    kas_competitor_best_keywords: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/kas/competitor-best-keywords`,
    kas_long_tail_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/kas/long-tail-keywords`,
    kas_least_competitive_keywords: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/kas/least-competitive-keywords`,
    kas_good_for_keyword_boosting: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/kas/good-for-keyword-boosting`,
    kas_expected_to_be_trending: (country: string, appId: string) =>
        `${host}/api/${country}/${appId}/kas/expected-to-be-trending`,
    get_string_keywords: (country: string, appId: string) => `${host}/api/${country}/${appId}/get-string-keywords`,
    app_competitors: (country: string, appId: string) => `/${country}/app/${appId}/competitors`,
    // Auth
    account_signup: () => `${host}/accounts/signup/`,
    auth_token_obtain_pair: () => `${host}/api/auth/obtain`,
    auth_token_refresh: () => `${host}/api/auth/refresh`,
    account_change_password: () => `${host}/accounts/password/change`,
    account_reset_password: () => `${host}/accounts/password/reset/`,
    google_login: () => `${host}/accounts/google/login/`,
    account_reset_password_from_key: (firstTokenPart: string, secondTokenPart: string) =>
        `${host}/accounts/password/reset/key/${firstTokenPart}-${secondTokenPart}/`,
    invite_registration: () => `${host}/api/team/invite-registration`,
    header_info: () => `${host}/api/header-info`,
    resend_confirmation: () => `/api/resend-confirmation`,
    sidebar_info: () => `${host}/api/sidebar-info`,
    trendingSearches: (country: string) => `${host}/api/${country}/trending-searches`,
    trendingSearchesPage: (country: string) => `/${country}/trending-searches`,
    get_teammates: () => `${host}/api/team/get-teammates`,
    r_program_index: () => `${host}/api/referral-program/`,
    r_program_invite: () => `${host}/api/referral-program/invite/`,
    api_credits_purchase: (purchaseId: string) => `/settings/purchase/api-credits/${purchaseId}/`,
    topCharts: (country: string) => `${host}/api/${country}/top-charts/`,
    search_application: (country: string) => `${host}/api/${country}/search-application`,
    track_app_by_url: (country: string) => `${host}/api/${country}/track-app-by-url`,
    untrackApp: (country: string, appId: string) => `${host}/api/${country}/${appId}/untrack-app`,
    api_featured_reviews_list: (storeId: string) => `${host}/api/${storeId}/featured-reviews/`,
    presets_list: (storeId: string, presetType: string) => `${host}/api/${storeId}/filter-presets/${presetType}/`,
    presets_create: (storeId: string, presetType: string) => `${host}/api/${storeId}/filter-presets/${presetType}/create/`,
    presets_manage: (storeId: string, presetType: string, presetId: number) =>
        `${host}/api/${storeId}/filter-presets/${presetType}/${presetId}/`,
    reviews_data: (storeId: string) => `${host}/api/${storeId}/app-reviews`,
    reviews_reply: (storeId: string) => `${host}/api/${storeId}/app-reviews-reply`,
    reviews_report: (storeId: string) => `${host}/api/${storeId}/app-reviews-report`,
    reviews_reply_remove: (appId: string, reviewId: number) => `${host}/api/${appId}/app-reviews/${reviewId}/remove-reply`,
    app_xlsx_review_report: (storeId: string) => `${host}/api/${storeId}/xlsx_review_report`,
    api_app_review_stats: (country: string, storeId: string) => `${host}/api/${country}/${storeId}/review-stats`,
    slack_revoke_app: (country: string, storeId: string) => `${host}/${country}/slack/${storeId}/revoke`,
    slack_auth_app: (country: string, storeId: string) => `${host}/${country}/slack/${storeId}/login`,
    rt_dir_list: () => `${host}/api/review-templates/dirs/`,
    rt_tmp_create: () => `${host}/api/review-templates/templates/create/`,
    rt_tmp_manage: (templateId: number) => `${host}/api/review-templates/templates/${templateId}/`,
    rt_dir_create: () => `${host}/api/review-templates/dirs/create/`,
    rt_dir_manage: (folderId: number) => `${host}/api/review-templates/dirs/${folderId}/`,
    rtag_structure: () => `${host}/api/review-tag/structure/`,
    rtagCategoryCreate: () => `${host}/api/review-tag/category/create/`,
    rtagCategoryManage: (categoryId: number) => `${host}/api/review-tag/category/${categoryId}/`,
    rtagCreate: () => `${host}/api/review-tag/create/`,
    rtagManage: (tagId: number) => `${host}/api/review-tag/${tagId}/`,
    rtagUserManage: (tagId: number) => `${host}/api/review-tag/user/${tagId}/`,
    rtag_user_bulk_delete: () => `${host}/api/review-tag/user/bulk_delete/`,
    locales: () => `/countries-localizations`,
    rtagUserCreate: () => `${host}/api/review-tag/user/create/`,
    googlePlayProviderList: () => `${host}/api/google-play-provider/`,
    googlePlayProviderDetail: (providerId: number) => `${host}/api/google-play-provider/${providerId}/`,
    googlePlayProviderGetAuthUrl: (providerId: number) => `${host}/api/google-play-provider/${providerId}/get-auth-url/`,
    getAppCount: () => `${host}/api/get-app-count`,
    get_app_locales: (locale: string) => `${host}/api/${locale}/get-app-locales/`,
    favor_app: (locale: string, storeId: string) => `${host}/api/${locale}/${storeId}/favor-app`,
    trafficScoreHistory: () => `${host}/api/history/traffic-score/`,
    difficultyHistory: () => `${host}/api/history/difficulty/`,
    searchadsHistory: () => `${host}/api/history/sap/`,
    trafficScoreHistoryExport: () => `${host}/api/history/traffic-score/export/`,
    searchadsHistoryExport: () => `${host}/api/history/sap/export/`,
    difficultyHistoryExport: () => `${host}/api/history/difficulty/export/`,
    get_cheap_plan: () => `${host}/api/settings/plans/get-plan-by-feature/`,
    auto_tag_rules_list: () => `${host}/api/auto-tag-rules/`,
    auto_tag_rules_detail: (ruleId: number) => `${host}/api/auto-tag-rules/${ruleId}/`,
    auto_reply_rules_list: () => `${host}/api/auto-reply-rules/`,
    auto_reply_rules_detail: (ruleId: number) => `${host}/api/auto-reply-rules/${ruleId}/`,
    autoreply_decline: (storeId: number) => `${host}/api/${storeId}/app-autoreply-decline`,
    appMeta: () => `${host}/api/app-short-meta`,
    topKeywords: (country: string) => `${host}/api/${country}/top-keywords`,
    [CustomReportsEnum.getList]: () => `${host}/custom-reports/api/channel/list/`,
    [CustomReportsEnum.getReportHistory]: (channelType: CustomReportChannelType) =>
        `${host}/custom-reports/api/channel/${channelType}/report-history/`,
    [CustomReportsEnum.createReport]: (channelType: CustomReportChannelType) =>
        `${host}/custom-reports/api/channel/${channelType}/create/`,
    [CustomReportsEnum.updateReport]: (channelType: CustomReportChannelType, channelId: number) =>
        `${host}/custom-reports/api/channel/${channelType}/${channelId}/`,
    new_subscription: () => '/settings/subscription/new',
    slackList: () => `${host}/api/slack/list/`,
    appUntrackedInfo: (storeId: string) => `${host}/api/${storeId}/untracked-info/`,
    apiAppComparativeReport: (country?: string, appId?: string) => `${host}/api/${country}/${appId}/comparative-report`,
    apiAppComparativeReportMetas: (country?: string, appId?: string) => `${host}/api/${country}/${appId}/comparative-report-metas`,
    appDataStats: (country?: string, appId?: string) => `${host}/api/${country}/${appId}/keyword-analytics/data-stats`,
    addKeywordsById: (country?: string, appId?: string) => `${host}/api/${country}/${appId}/add-keywords-by-id`,
    appXlsxComparativeReport: (country?: string, appId?: string) => `${host}/${country}/app/${appId}/xlsx_comparative_report`,
    profileData: (country?: string, appId?: string) => `${host}/api/${country}/${appId}/app-profile`,
};

export const apiUrls = {
    plans: () => `${host}/api/settings/plans/`,
    invoices: () => `${host}/api/settings/invoices/`,
    sendCustomPlan: () => `${host}/api/settings/plans/send-custom-plan/`,
    getSuitablePlan: () => `${host}/api/settings/plans/get-suitable-plan/`,
    geCoupon: () => `${host}/api/settings/plans/get-coupon/`,
    recurringProfiles: (recurringProfileId?: number) =>
        `${host}/recurring-profiles/${recurringProfileId ? `${recurringProfileId}/` : ''}`,
    setAsDefaultRecurringProfile: (recurringProfileId: number) =>
        `${host}/recurring-profiles/${recurringProfileId}/set-as-default/`,
    confirmRecurringProfile: (recurringProfileId: number) => `${host}/recurring-profiles/${recurringProfileId}/confirm/`,
    validateVatNumber: () => `${host}/api/settings/billing-information/validate-vat-number/`,
    billingInformation: () => `${host}/api/settings/billing-information/`,
    reviewTranslate: (storeId: string, reviewId: number) => `${host}/api/${storeId}/app-reviews/${reviewId}/translate`,
    reviewFavorite: (storeId: string, reviewId: number) => `${host}/api/${storeId}/app-reviews/${reviewId}/favorite`,
    getBenchmarkData: (country: string, month: string, appType: string, storeType?: string) =>
        `${host}/api/${country}/benchmark/?month=${month}&appType=${appType}&storeType=${storeType}`,
    getBenchmarkExportXLSData: (country: string, month: string, appType: string) =>
        `${host}/api/${country}/benchmark/export?month=${month}&appType=${appType}`,
    getSuggestedCompetitors: (country: string, storeId: string) => `${host}/api/${country}/${storeId}/suggested-competitors`,
    replyBoardAppSettings: (appId: string) => `${host}/api/${appId}/reply-board-app-settings`,
    createProductAnalyticsEvent: () => `${host}/api/analytics-events/create/`,
    calendlyNotify: () => `${host}/api/calendly-notify`,
    rtagCategoryCheckRelations: (categoryId: number) => `${host}/api/review-tag/category/${categoryId}/check-relations/`,
    rtagCheckRelations: (tagId: number) => `${host}/api/review-tag/${tagId}/check-relations/`,

    getPlan: (planId: number) => `${host}/api/settings/plans/${planId}/`,
    getApiPlan: (apiPlanId: number) => `${host}/api/settings/api-plans/${apiPlanId}/`,

    createSubscription: () => `${host}/api/settings/subscription/`,
    createApiSubscription: () => `${host}/api/settings/api-subscription/`,

    getSubscription: (subscriptionId: string) => `${host}/api/settings/subscription/${subscriptionId}/`,
    getApiSubscription: (apiSubscriptionId: string) => `${host}/api/settings/api-subscription/${apiSubscriptionId}/`,

    recalculatePriceSubscription: (subscriptionId: string) =>
        `${host}/api/settings/subscription/${subscriptionId}/recalculate-price/`,
    recalculatePriceApiSubscription: (apiSubscriptionId: string) =>
        `${host}/api/settings/api-subscription/${apiSubscriptionId}/recalculate-price/`,
    recalculateCampaignPrice: (campaignId: string) => `${host}/api/settings/campaign/${campaignId}/recalculate-price/`,
    payCampaign: (campaignId: string) => `${host}/api/settings/campaign/${campaignId}/pay/`,

    paySubscription: (subscriptionId: string) => `${host}/api/settings/subscription/${subscriptionId}/pay/`,
    payApiSubscription: (apiSubscriptionId: string) => `${host}/api/settings/api-subscription/${apiSubscriptionId}/pay/`,

    getInvoiceSubscription: (subscriptionId: string) => `${host}/api/settings/subscription/${subscriptionId}/invoice/`,
    getInvoiceApiSubscription: (apiSubscriptionId: string) =>
        `${host}/api/settings/api-subscription/${apiSubscriptionId}/invoice/`,
    getInvoiceCampaignSubscription: (campaignSubscriptionId: string) =>
        `${host}/api/settings/campaign/${campaignSubscriptionId}/invoice/`,
    allFeatures: () => `${host}/api/settings/plans/all-features/`,
    scheduleLiveDemo: () => `${host}/api/settings/schedule-live-demo/`,
    upgradeTrial: () => `${host}/api/settings/subscription/upgrade-trial/`,
    businessPlanSlackQuestion: () => `${host}/api/settings/request-business-plan/`,
    getKeywordsRankingDistribution: (countryCode: string, storeId: string) =>
        `${host}/api/${countryCode}/${storeId}/keyword-analytics/ranking-distribution`,
    exportXlsxRankingDistribution: (countryCode: string, storeId: string) =>
        `${host}/api/${countryCode}/${storeId}/keyword-analytics/xlsx-ranking-distribution`,
    getKeywordExplorer: () => `${host}/api/keyword-explorer/search`,
    getKeywordExplorerRankHistory: () => `${host}/api/keyword-explorer/positions-history`,
    getFrequentKeyword: (country: string, storeId: string) =>
        `${host}/api/${country}/${storeId}/keyword-explorer/most-frequent-keyword`,
    updateAppMeta: (country: string) => `${host}/api/${country}/top-charts/app-meta`,
    getKeywordExplorerAnalytics: () => `${host}/api/keyword-explorer/analytics`,
    getKeywordExplorerInputHistory: () => `${host}/api/keyword-explorer/input-history`,
    getKeywordExplorerStatistics: () => `${host}/api/keyword-explorer/statistics`,
    getTotalAppHistory: () => `${host}/api/keyword-explorer/total-apps-history`,
    setCompetitorToTrackedApps: (country: string, storeId: string) => `${host}/api/${country}/${storeId}/add-as-competitor-to`,
    topPositionsHistory: () => `${host}/api/keyword-explorer/top-positions-history`,
    addKeywordsMultipleApps: (country: string) => `${host}/api/${country}/add-keywords-multiple-apps`,
    keywordProfile: () => `${host}/api/keyword-explorer/profile`,
    getKeywordSuggestions: () => `${host}/api/keyword-explorer/keyword-suggestions`,
    appReview: (storeId: string, reviewId: string) => `${host}/api/${storeId}/app-reviews/${reviewId}/`,
    appReviewPreview: (storeId: string, reviewId: string) => `${host}/api/${storeId}/app-reviews/preview/${reviewId}/`,
    getCampaignData: (country: string, campaignId: string) => `${host}/api/${country}/campaign/${campaignId}/`,
    getRemovedDowngradePlanMeta: () => `${host}/api/settings/plans/downgrade-removed-entities/`,
    removedDowngradePlanMeta: () => `${host}/settings/remove-excessive-objects-confirmation/`,

    [ZendeskUrlsEnum.getList]: () => `${host}/zendesk/api/internal/api_integration/settings/`,
    [ZendeskUrlsEnum.create]: () => `${host}/zendesk/api/internal/api_integration/settings/create/`,
    [ZendeskUrlsEnum.manage]: (integrationId: number) =>
        `${host}/zendesk/api/internal/api_integration/settings/${integrationId}/`,
    [ZendeskUrlsEnum.applicationManage]: (integrationId: number, storeId: string) =>
        `${host}/zendesk/api/internal/api_integration/settings/${integrationId}/applications/${storeId}/`,
    [ZendeskUrlsEnum.groupManage]: (integrationId: number) =>
        `${host}/zendesk/api/internal/api_integration/external_panel/${integrationId}/groups/`,

    [OmnideskUrlsEnum.getList]: () => `${host}/omnidesk/api/settings/`,
    [OmnideskUrlsEnum.create]: () => `${host}/omnidesk/api/settings/create/`,
    [OmnideskUrlsEnum.manage]: (integrationId: number) => `${host}/omnidesk/api/settings/${integrationId}/`,
    [OmnideskUrlsEnum.applicationManage]: (integrationId: number, storeId: string) =>
        `${host}/omnidesk/api/settings/${integrationId}/applications/${storeId}/`,
    [OmnideskUrlsEnum.groupManage]: (integrationId: number) => `${host}/omnidesk/api/external_panel/${integrationId}/groups/`,

    [UsedeskUrlsEnum.getList]: () => `${host}/usedesk/api/settings/`,
    [UsedeskUrlsEnum.create]: () => `${host}/usedesk/api/settings/create/`,
    [UsedeskUrlsEnum.manage]: (integrationId: number) => `${host}/usedesk/api/settings/${integrationId}/`,
    [UsedeskUrlsEnum.applicationManage]: (integrationId: number, storeId: string) =>
        `${host}/usedesk/api/settings/${integrationId}/applications/${storeId}/`,
    [UsedeskUrlsEnum.groupManage]: (integrationId: number) => `${host}/usedesk/api/external_panel/${integrationId}/agent-groups/`,
    getTagAnalysisStats: () => `${host}/tag-analysis-statistics`,
    getTagAnalysisReport: () => `${host}/tag-analysis-tags-report`,
    getHubspotToken: () => `${host}/api/hubspot/token/`,
    rustoreProviderList: () => `${host}/api/rustore-provider/`,
    rustoreProviderDetail: (providerId: number) => `${host}/api/rustore-provider/${providerId}/`,
    removeTeammateUrl: () => `${host}/api/team/remove-teammate`,
};

export const FrontendUrls = {
    onboardingState: () => `${host}/api/frontend-settings/onboarding-state/`,
    invoice_api_credits_purchase: (invoiceId: string) => `/settings/purchase/api-credits/${invoiceId}/invoice`,
    apiSubscription: (subscriptionId: string) => `/settings/api-subscription/${subscriptionId}/`,
    subscription: (id: string) => `/settings/subscription/${id}/`,
    switchAutoRenew: () => '/settings/switch_autorenew',
    notFound: () => `/not-found`,
    confirmEmail: () => `${host}/accounts/confirm-email/`,
    resendCode: () => `${host}/accounts/resend-confirmation/`,
    settingProfile: () => `/settings/profile`,
    settings: () => `/settings`,
    statisticPage: () => `/statistics`,
    comparativeReportPage: () => `/comparative_report`,
    noLocale: (country: string, appId: string) => `/${country}/app/${appId}/no_locale`,
    appCrossing: (country?: string, appId?: string) => `/${country}/app/${appId}/crossing`,
    slackAuth: () => `${host}/api/slack/auth/`,
    userProfile: () => `${host}/api/settings/user-profile/`,
    createFreeTrial: () => `${host}/api/settings/subscription/get-trial/`,
    userAppCompetitors: (country: string, storeId: string) => `${host}/api/${country}/${storeId}/user-app-competitors`,
    addBulkCompetitors: (country: string, storeId: string) => `${host}/api/${country}/${storeId}/add-bulk-competitors`,
    removeBulkCompetitors: (country: string, storeId: string) => `${host}/api/${country}/${storeId}/remove-bulk-competitors`,
    googlePlayProvider: () => '/api/google-play-provider/redirect-handler',
};

export const asoAuditUrl = 'https://angletech.ai/aso';
export const freeVideoCourseUrl = 'https://asodesk.com/free-video-course';
export const slackGroupLink = 'https://asodesk.com/slack-group';
export const helpCenterUrl = 'https://help.asodesk.com/';
export const startGuideUrl = 'https://help.asodesk.com/start-guide';
export const appStoreArticleUrl = 'https://help.asodesk.com/articles/3788944-app-store-editorial-in-search-results';
export const googlePlayArticleUrl = 'https://help.asodesk.com/articles/3746242-cluster-search-results-what-is-it';

export const appUrls = (url: string, appParams: AppsRequestParamsType) => {
    if (!(url in Urls)) throw new Error('this url has no, check url name');

    return Urls[url](appParams.country, appParams.appId);
};

export const googleCommentPostingPolicyUrl = 'https://play.google.com/about/comment-posting-policy/';

export const googleIntegrationsGuideUrl = 'https://help.asodesk.com/articles/4670880-google-play-console-integration';
export const guideStoreConnect = 'https://help.asodesk.com/articles/3555130-app-store-connect-integration';
export const guideZendeskConnect = 'https://help.asodesk.com/articles/5303930-zendesk-api-integration';
export const guideOmnideskConnect = 'https://help.asodesk.com/articles/5577195-omnidesk-integration';
export const guideUsedeskConnect = 'https://help.asodesk.com/usedesk-integration';

export const guideAppStoreConnect = 'https://help.asodesk.com/articles/3555130-app-store-connect-integration';
export const guideGoogleConnect = 'https://help.asodesk.com/articles/4670880-google-play-console-integration';

export const guideDailyKeywordStats = 'https://help.asodesk.com/daily-keyword-stats';

export const translateToEnglishUrl = (text: string) => `https://translate.google.com/#auto/en/${window.encodeURIComponent(text)}`;

// Agreement & Polices for login & signup pages
export const signUpAgreementUrl = 'https://asodesk.com/docs/en-cy/agreement';
export const signUpPrivacyPolicyUrl = 'https://asodesk.com/docs/en-cy/privacy_policy';
export const signUpCookiePolicyUrl = 'https://asodesk.com/docs/en-cy/cookie_policy';

// Agreements & Polices for platron (and later possibly PSB) payment page
export const paymentAgreementUrl = 'https://asodesk.com/docs/ru-en/agreement';
export const paymentAcquiringAgreementUrl = 'https://asodesk.com/docs/ru-en/acquiring';
export const paymentPrivacyPolicyUrl = 'https://asodesk.com/docs/ru-en/privacy_policy';
export const paymentUseDataPolicyUrl = 'https://asodesk.com/docs/ru-en/data-use-policy';

// Agreements & Polices for campaign
export const campaignPaymentAgreementUrl = 'https://asodesk.com/docs/en-cy/services_agreement';

// TODO: change this field to hubspot
export const asodeskTourCalendly = 'https://calendly.com/asodesk_sd/30min?hide_landing_page_details=1&hide_event_type_details=1';

export const asodeskProductTourHubspot = 'https://meetings-eu1.hubspot.com/asodesk/product-tour';
export const asodeskProductTourCsHubspot = 'https://meetings-eu1.hubspot.com/asodesk/product-tour-cs';

export const hqUrlWithoutProtocol = 'hq.asodesk.com';
export const hqUrl = `https://${hqUrlWithoutProtocol}`;

export const appleSearchAdsUrl = 'https://angletech.ai/asa';

export const addAppGuideUrl = 'https://help.asodesk.com/articles/3519463-add-applications';
