import { SubPlanType } from 'state/plans/PlansType';
import { FREE_PLAN_ID } from 'state/plans/customEmptyPlan';
import { useAppSelector } from 'hooks/useAppSystem';

export const usePlansGetter = () => {
    const plans = useAppSelector((state) => state.plans);
    const {
        isTrial,
        currentPlan,
        aso,
        reviews,
        reviewsAso,
        services,
        promo,
        enterprisePlan,
        lastExpiredTrial,
        plansDowngradeMeta,
    } = plans;

    const isCurrentPlanFree = Number(currentPlan?.id) === FREE_PLAN_ID;

    const reviewsPlans = reviews.reduce((result: SubPlanType[], reviewPlan) => [...result, ...reviewPlan.plans], []);
    const reviewsPlansIds = reviewsPlans.map(({ id }) => id);

    const asoPlans = aso.reduce((result: SubPlanType[], asoPlan) => [...result, ...asoPlan.plans], []);
    const asoPlansIds = asoPlans.map(({ id }) => id);
    const asoPlansNextIds = asoPlans.map(({ nextPlanId }) => nextPlanId);

    const reviewsAsoPlans = reviewsAso.reduce(
        (result: SubPlanType[], reviewsAsoPlan) => [...result, ...reviewsAsoPlan.plans],
        []
    );
    const reviewsAsoPlansIds = reviewsAsoPlans.map(({ id }) => id);
    const reviewsAsoPlansNextIds = reviewsAsoPlans.map(({ nextPlanId }) => nextPlanId);

    const currentFreePlan: SubPlanType | undefined = currentPlan
        ? {
              id: FREE_PLAN_ID,
              name: currentPlan.name,
              features: [],
              tools: [],
              reviewsFeatures: [],
              pricing: [],
              keywords: currentPlan.keywordLimit || 0,
              reviews: currentPlan.repliesLimit || 0,
              isReviewAso: false,
              apps: currentPlan.appLimit,
              teammates: currentPlan.teammatesLimit,
              order: currentPlan.order,
              base: 0,
          }
        : undefined;

    return {
        isTrial,
        currentPlan,
        isCurrentPlanFree,
        aso,
        reviews,
        reviewsAso,
        services,
        promo,
        enterprisePlan,
        lastExpiredTrial,
        reviewsPlans,
        reviewsPlansIds,
        asoPlans,
        asoPlansIds,
        reviewsAsoPlans,
        reviewsAsoPlansIds,
        plansDowngradeMeta,
        asoPlansNextIds,
        reviewsAsoPlansNextIds,
        currentFreePlan,
    };
};
