import React, { ReactNode } from 'react';
import { MainButtonElementType } from 'components/buttons/mainButton/ButtonElement';

export enum OnboardingStepEnum {
    YOURSELF_STEP_FIRST = 'YOURSELF_STEP_FIRST',
    YOURSELF_STEP_SECOND = 'YOURSELF_STEP_SECOND',
    YOURSELF_STEP_THIRD = 'YOURSELF_STEP_THIRD',
    YOURSELF_STEP_FOURTH = 'YOURSELF_STEP_FOURTH',
    SELECT_COUNTRY_STEP = 'SELECT_COUNTRY_STEP',
    ADD_FIRST_APP_STEP = 'ADD_FIRST_APP_STEP',
    GLOBAL_NAVIGATION_STEP = 'GLOBAL_NAVIGATION_STEP',
    LOCAL_NAVIGATION_AND_TOOLS_STEP = 'LOCAL_NAVIGATION_AND_TOOLS_STEP',
    POSSIBILITIES_OF_ASODESK_STEP = 'POSSIBILITIES_OF_ASODESK_STEP',
    REVIEWS_RATINGS_STEP = 'REVIEWS_RATINGS_STEP',
    STORES_ANALYTICS_STEP = 'STORES_ANALYTICS_STEP',
    CONNECTIONS_HUB_STEP = 'CONNECTIONS_HUB_STEP',
    KEYWORD_BOOST_STEP = 'KEYWORD_BOOST_STEP',
    SCHEDULE_A_DEMO_STEP = 'SCHEDULE_A_DEMO_STEP',
    CHOOSE_A_PLAN_STEP = 'CHOOSE_A_PLAN_STEP',
    FINISH_ONBOARDING_STEP = 'FINISH_ONBOARDING_STEP',
}

export type BaseOnboardingStepType = {
    url?: string;
};

export type YourselfStepFirstType = BaseOnboardingStepType & {
    companyName: string;
    kindOfWork: string;
    role: string;
    'kindOfWork-other'?: string;
    'role-other'?: string;
};

export type YourselfStepSecondType = BaseOnboardingStepType & {
    peopleCount: string;
    approximatelyPeopleCount: string;
    appsCount: string;
};

export type YourselfStepThirdType = BaseOnboardingStepType & {
    hearAboutUs: string;
    'hearAboutUs-other'?: string;
};

export type OnboardingStepsType = {
    [OnboardingStepEnum.YOURSELF_STEP_FIRST]: YourselfStepFirstType;
    [OnboardingStepEnum.YOURSELF_STEP_SECOND]: YourselfStepSecondType;
    [OnboardingStepEnum.YOURSELF_STEP_THIRD]: YourselfStepThirdType;
    [OnboardingStepEnum.YOURSELF_STEP_FOURTH]: BaseOnboardingStepType;
    [OnboardingStepEnum.SELECT_COUNTRY_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.ADD_FIRST_APP_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.GLOBAL_NAVIGATION_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.REVIEWS_RATINGS_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.STORES_ANALYTICS_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.CONNECTIONS_HUB_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.KEYWORD_BOOST_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.SCHEDULE_A_DEMO_STEP]: BaseOnboardingStepType;
    [OnboardingStepEnum.CHOOSE_A_PLAN_STEP]: BaseOnboardingStepType & { leaveRequestPlanName?: string };
    [OnboardingStepEnum.FINISH_ONBOARDING_STEP]: BaseOnboardingStepType;
};

export type OnboardingStepDataType = OnboardingStepsType[keyof OnboardingStepsType];

export type OnboardingRadioListType = { title: string; name: string; options: string[]; isOther?: boolean };

export type OnboardingStateType = {
    isReadyTour?: boolean;
    isShow?: boolean;
    isShowTour?: boolean;
    onboardingCurrentStepId: OnboardingStepEnum;
    onboardingSteps: OnboardingStepsType;
};

export type OnboardingControlType = Omit<MainButtonElementType, 'onClick'> & {
    onClick?: (data?: any) => void;
};

export type OnboardingYourselfStepDataItemType = {
    title?: string;
    subTitle?: string;
    titleSection?: string;
    titleClassName?: string;
    description?: React.ReactNode;
    inputName?: string;
    radioLists?: OnboardingRadioListType[];
    defaultDataValues?: OnboardingStepDataType;
    next?: OnboardingControlType;
    back?: OnboardingControlType;
    otherControls?: {
        direction?: 'horizontal' | 'vertical';
        controls: OnboardingControlType[];
    };
    image?: {
        src: string;
        alt: string;
    };
    Content?: ReactNode;
    featureLists?: {
        title: string;
        list: string[];
    };
    containerClassName?: string;
    wrapperClassName?: string;
};

export type OnboardingYourselfStepDataType = {
    [key in OnboardingStepEnum]?: OnboardingYourselfStepDataItemType;
};
