export const getIsGuruAso = (planName: string) => planName.toLowerCase().includes('guru aso');
export const getIsPowerAso = (planName: string) => planName.toLowerCase().includes('power aso');
export const getIsUltimateAso = (planName: string) => planName.toLowerCase().includes('ultimate aso');

export const getIsAsoBusinessPlan = (planName: string) =>
    getIsGuruAso(planName) || getIsPowerAso(planName) || getIsUltimateAso(planName);

// TODO: add for plan new prop is_only_annual to backend
export const checkIsBusiness = (planName: string) => {
    const isBusiness = planName.toLowerCase().includes('business');
    const isPowerAso = getIsPowerAso(planName);
    const isPowerReviews = planName.toLowerCase().includes('power reviews');
    const isUltimate = planName.toLowerCase().includes('ultimate');

    return isBusiness || isPowerAso || isUltimate || isPowerReviews;
};
