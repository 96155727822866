import { PlansActionTypeEnum } from 'state/plans/actionTypes';
import { PlansActionType } from './actions';
import { PlansInitialState } from './PlansInitialState';

const plans = (state = PlansInitialState, action: PlansActionType) => {
    switch (action.type) {
        case PlansActionTypeEnum.SET_PLANS_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        case PlansActionTypeEnum.SET_PLANS_FEATURES: {
            return {
                ...state,
                plansFeatures: action.data,
                plansFeaturesWereLoaded: true,
            };
        }
        case PlansActionTypeEnum.SET_DOWNGRADE_PLANS_META: {
            return {
                ...state,
                plansDowngradeMeta: {
                    ...state.plansDowngradeMeta,
                    ...action.data,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export default plans;
