import axios, { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import { Urls } from 'utils/Urls';
import { headerInfoResponseAdapter } from 'state/asodeskHeader/HeaderInfoResponseAdapter';
import { HeaderCountryType } from 'state/asodeskHeader/HeaderCountryType';
import { toast } from 'react-toastify';
import { setSystemLocaleAction } from 'state/system/actions';
import { CANCELED_REQUEST_MESSAGE } from 'constants/requestMessagesConstants';
import { SearchApplicationResponseType } from 'state/asodeskHeader/SearchApplicationResponseType';
import { getNowTimestamp } from 'utils/helpers/date/date';
import { RootState } from 'state/store';
import { setHeaderInfoAction } from './actions';

type CountryHeaderInfoResponseType = {
    code: string;
    name: string;
    keyword_count: number;
};

export type HeaderInfoResponseType = {
    countries: CountryHeaderInfoResponseType[];
    current_country: CountryHeaderInfoResponseType;
    avatar: string;
};

let cancelToken: CancelTokenSource;

export const getAsodeskHeaderInfo = (appId?: string) => async (dispatch: Dispatch) => {
    try {
        if (cancelToken) cancelToken.cancel(CANCELED_REQUEST_MESSAGE);

        cancelToken = axios.CancelToken.source();

        const response = await axios.get<HeaderInfoResponseType>(`${Urls.header_info()}`, {
            params: {
                storeid: appId,
            },
            cancelToken: cancelToken.token,
        });
        const { countries, userDefaultCountry } = headerInfoResponseAdapter(response.data);
        dispatch(setHeaderInfoAction(countries, userDefaultCountry, response.data.avatar));
    } catch (error) {
        if (error.message !== CANCELED_REQUEST_MESSAGE) {
            console.error(error);
        }
    }
};

export const setCurrentCountry = (appCountry: HeaderCountryType) => (dispatch: Dispatch) => {
    dispatch(setSystemLocaleAction(appCountry.code));
};

export const sendEmailConfirmation = () => async () => {
    try {
        await axios.post(Urls.resend_confirmation());
    } catch (e) {
        if (e.response.data && e.response.data.error) toast.error(e.response.data.error);
    }
};

let cancelTokenSearchApp: CancelTokenSource;

export const searchApp = (query: string) => async (dispatch: Dispatch, store: () => RootState) => {
    try {
        const {
            system: { locale },
        } = store();

        if (locale) {
            if (cancelTokenSearchApp) cancelTokenSearchApp.cancel(CANCELED_REQUEST_MESSAGE);

            cancelTokenSearchApp = axios.CancelToken.source();

            const response = await axios.get<SearchApplicationResponseType>(Urls.search_application(locale), {
                params: {
                    request_id: getNowTimestamp(),
                    query,
                },
                cancelToken: cancelTokenSearchApp.token,
            });

            return response.data.data;
        }
    } catch (e) {
        if (e && e.response && e.response.data) {
            console.error(e.response.data);

            if (e.response.data.error && e.response.data.error) {
                const adaptedError = e.response.data.error.replace("['", '').replace("']", '');

                toast.error(adaptedError);

                throw adaptedError;
            }
        }
    }
};
