import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { DefaultTab } from 'newComponents/tab/defaultTab/DefaultTab';
import { ReviewsTrialPlanStepContainer } from 'modules/modal/onboarding/choosePlanStep/ReviewsTrialPlanStepContainer';
import { AsoTrialPlanStepContainer } from 'modules/modal/onboarding/choosePlanStep/AsoTrialPlanStepContainer';
import { EnterprisePlanCardContainer } from 'modules/settings/plans/planTabs/enterprisePlanCard/EnterprisePlanCardContainer';
import { PlanCardsList } from 'newComponents/plans/planCardsList/PlanCardsList';
import { usePlans } from 'hooks/data/usePlans/usePlans';
import { PLAN_ID_GURU_ASO, PLAN_ID_GURU_REVIEWS, REQUESTED_PLAN_NAME } from 'modules/modal/onboarding/choosePlanStep/constants';
import { useDispatch } from 'react-redux';
import { TrialStepNextData } from 'modules/modal/onboarding/choosePlanStep/trialStepTypes';
import { BallLoader } from 'components/loader/ballLoader/BallLoader';
import { useProductAnalytics } from 'hooks/useProductAnalytics';
import { saveOnboardingState, SET_PLAN_TRIAL_ERROR, setOnboardingTrialPlan } from 'state/onboarding/controller';
import { getRequestInfo } from 'state/requestInfo/controller';
import { useTheme } from 'hooks/state/useTheme';
import { useNavigate } from 'react-router-dom';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { OnboardingStepEnum } from 'state/onboarding/types';
import { useSystem } from 'hooks/state/useSystem';
import { useAppSelector } from 'hooks/useAppSystem';
import { getPlansData } from 'state/plans/controller';

export const ChoosePlanStepContainer: FC = () => {
    const [activeTab, setActiveTab] = useState(0);

    const dispatch = useDispatch();

    const { isMobile } = useTheme();

    const navigate = useNavigate();

    const { appId } = useSystem();

    const { myAppsUrl, asoToolsAppProfileUrl } = useRoutesUrl();

    const onboardingData = useAppSelector((state) => state.onboarding);

    const { sendEvent } = useProductAnalytics();

    const onNextWithGetTrial = async (nextProps?: TrialStepNextData) => {
        if (nextProps) {
            const { isSendRequest, planId, planName, requestedPlanName } = nextProps;

            const [adaptedRequestedPlanName] = requestedPlanName?.split(' ') || [];

            try {
                await dispatch(setOnboardingTrialPlan(planId, isSendRequest));
            } catch (error) {
                if (error.message === SET_PLAN_TRIAL_ERROR) {
                    toast.error(error.message);
                }
            }

            dispatch(getPlansData());

            await sendEvent({
                actionName: 'plan_selected',
                toolName: 'onboarding',
                extras: {
                    name: planName,
                },
            });
            await dispatch(getRequestInfo());
            await dispatch(
                saveOnboardingState({
                    ...onboardingData,
                    onboardingSteps: {
                        ...onboardingData.onboardingSteps,
                        [OnboardingStepEnum.CHOOSE_A_PLAN_STEP]: {
                            ...onboardingData.onboardingSteps[OnboardingStepEnum.CHOOSE_A_PLAN_STEP],
                            ...(isSendRequest ? { leaveRequestPlanName: adaptedRequestedPlanName } : {}),
                        },
                    },
                    onboardingCurrentStepId: OnboardingStepEnum.FINISH_ONBOARDING_STEP,
                })
            );

            if (isMobile || !appId) {
                navigate(myAppsUrl);
            } else {
                navigate(asoToolsAppProfileUrl);
            }
        }
    };

    const { aso, reviews, reviewsAso, isLoadingPlansData } = usePlans();

    const [isLoadingLeaveRequestEnterprisePlan, setIsLoadingLeaveRequestEnterprisePlan] = useState(false);

    const onLeaveRequestAso = async (isSendRequest = false, requestedPlanName?: string) => {
        const trialPlanId = PLAN_ID_GURU_ASO;

        await onNextWithGetTrial({
            planId: trialPlanId,
            planName: REQUESTED_PLAN_NAME[trialPlanId],
            isSendRequest,
            requestedPlanName,
        });
    };

    const onLeaveRequestReviews = async (isSendRequest = false, requestedPlanName?: string) => {
        const trialPlanId = PLAN_ID_GURU_REVIEWS;

        await onNextWithGetTrial({
            planId: trialPlanId,
            planName: REQUESTED_PLAN_NAME[trialPlanId],
            isSendRequest,
            requestedPlanName,
        });
    };

    const columnCount = 5;

    const callbackEnterprisePlanAso = async () => {
        setIsLoadingLeaveRequestEnterprisePlan(true);
        await onLeaveRequestAso(true, 'Enterprise');
        setIsLoadingLeaveRequestEnterprisePlan(false);
    };

    const callbackEnterprisePlanReviews = async () => {
        setIsLoadingLeaveRequestEnterprisePlan(true);
        await onLeaveRequestReviews(true, 'Enterprise');
        setIsLoadingLeaveRequestEnterprisePlan(false);
    };

    const tabByDevice = [
        {
            id: 0,
            text: 'ASO',
            Content: isLoadingPlansData ? (
                <BallLoader className="!top-200" elementCenter />
            ) : (
                <PlanCardsList columnCount={columnCount}>
                    {aso.map((plan) => (
                        <AsoTrialPlanStepContainer
                            key={plan.id}
                            plan={plan}
                            onLeaveRequest={onLeaveRequestAso}
                            onNext={onNextWithGetTrial}
                            reviewsAso={reviewsAso}
                            showASOReview={false}
                        />
                    ))}
                    <EnterprisePlanCardContainer
                        isLoading={isLoadingLeaveRequestEnterprisePlan}
                        callback={callbackEnterprisePlanAso}
                        isStickyControls
                    />
                </PlanCardsList>
            ),
            onClickHandler: () => setActiveTab(0),
        },
        {
            id: 1,
            text: 'Reply-to-Reviews',
            Content: isLoadingPlansData ? (
                <BallLoader className="!top-200" elementCenter />
            ) : (
                <div>
                    <PlanCardsList columnCount={columnCount}>
                        {reviews.map((plan) => (
                            <ReviewsTrialPlanStepContainer
                                key={plan.id}
                                plan={plan}
                                onLeaveRequest={onLeaveRequestReviews}
                                onNext={onNextWithGetTrial}
                            />
                        ))}
                        <EnterprisePlanCardContainer
                            isLoading={isLoadingLeaveRequestEnterprisePlan}
                            callback={callbackEnterprisePlanReviews}
                            isStickyControls
                        />
                    </PlanCardsList>
                </div>
            ),
            onClickHandler: () => setActiveTab(1),
        },
    ];

    return (
        <DefaultTab
            tabs={tabByDevice}
            activeId={activeTab}
            className="px-24 -mx-24"
            contentClassName={classNames('flex-1 overflow-y-auto px-24 -mx-24')}
        />
    );
};
