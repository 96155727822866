import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCompetitorsAndTrackedAppsService } from 'hooks/useCompetitorsAndTrackedAppsService';
import { useAppProfileService } from 'hooks/data/useAppProfileService';
import { MODAL_NAME_REMOVE_APP } from 'constants/modalNameConstants';
import { trackApp } from 'state/trackedApps/controller';
import { getCurrentAppProfile } from 'state/appProfile/controller';
import { useTogglePopup } from 'hooks/state/usePopup';
import { AskRemoveAppModalPayloadDataType } from 'modules/dashboard/askRemoveAppModal/AskRemoveAppModalPayloadDataType';
import { useSystem } from 'hooks/state/useSystem';
import { useReloadPlanInfo } from 'hooks/useReloadPlanInfo';
import { useAppSelector } from 'hooks/useAppSystem';

export const useAppToolBar = () => {
    const dispatch = useDispatch();

    const [isTrackButtonLoading, setTrackButtonLoading] = useState(false);

    const { appId, locale } = useSystem();

    const appStore = useAppSelector(({ appToolBarSettings }) => appToolBarSettings.definedAppStore);

    const { trackedApps, canAddToMyApps, canAddToMyCompetitors, canAddCompetitor } = useCompetitorsAndTrackedAppsService();
    const { isDemo, appProfile, isApp, storeLink, storeType, isConnected } = useAppProfileService();
    const { canRemoveCurrentApp } = useCompetitorsAndTrackedAppsService();

    const currentAppIndex = useMemo(() => trackedApps.findIndex((app) => app.store_id === appId), [appId, trackedApps]);
    const trackedAppsWithoutCurrent = appStore ? trackedApps.filter((app) => app.type === appStore) : [...trackedApps];
    trackedAppsWithoutCurrent.splice(currentAppIndex, 1);

    const showOnlyOneApp = isDemo || trackedAppsWithoutCurrent.length === 0 || !isApp;

    const notTrackedApp = appId && canAddToMyApps(appId);
    const { togglePopupWithData: toggleRemoveAppPopup } = useTogglePopup<AskRemoveAppModalPayloadDataType>(MODAL_NAME_REMOVE_APP);

    const onToggleRemoveAppModal = () => {
        const { store_id: storeId, logo, name } = trackedApps[currentAppIndex];

        toggleRemoveAppPopup({
            data: {
                storeId,
                logo,
                name,
            },
        });
    };

    const { reloadPlanInfo } = useReloadPlanInfo();

    const onClickTrackApp = async () => {
        try {
            setTrackButtonLoading(true);
            if (locale && appId) {
                await dispatch(trackApp(locale, appId));
                await dispatch(getCurrentAppProfile({ country: locale, appId }));
            }
        } finally {
            setTrackButtonLoading(false);
            await reloadPlanInfo();
        }
    };

    return {
        appId,
        isDemo,
        appProfile,
        showOnlyOneApp,
        trackedApps,
        currentAppIndex,
        trackedAppsWithoutCurrent,
        canAddToMyApps,
        storeLink,
        storeType,
        isConnected,
        isApp,
        isTrackButtonLoading,
        onClickTrackApp,
        locale,
        canRemoveCurrentApp,
        onToggleRemoveAppModal,
        notTrackedApp,
        canAddToMyCompetitors,
        canAddCompetitor,
    };
};
