import { CategoryPlanType } from 'state/plans/PlansType';
import { INDIVIDUAL_PLAN_STATS } from 'modules/modal/planModal/constants';
import { INFINITE_NUMBER } from 'constants/numbers';

export const CUSTOM_PLAN_ID = -100;

export const customEmptyPlan: CategoryPlanType = {
    id: CUSTOM_PLAN_ID,
    name: 'Enterprise Plan',
    isBestPlan: false,
    plans: [
        {
            id: CUSTOM_PLAN_ID,
            name: 'Enterprise Plan',
            features: [],
            tools: [],
            reviewsFeatures: [],
            pricing: [],
            apps: INDIVIDUAL_PLAN_STATS,
            teammates: INDIVIDUAL_PLAN_STATS,
            keywords: INDIVIDUAL_PLAN_STATS,
            reviews: INDIVIDUAL_PLAN_STATS,
            isReviewAso: false,
            order: INFINITE_NUMBER,
            base: 1,
        },
    ],
};

export const freeFeatures = [
    {
        title: 'Limits',
        values: [{ name: '200 keywords' }, { name: '1 app' }, { name: '1 teammate' }, { name: '100 API credits' }],
    },
];

export const FREE_PLAN_ID = 58;

export const freePlan: CategoryPlanType = {
    id: FREE_PLAN_ID,
    name: 'Free Plan',
    isBestPlan: false,
    plans: [
        {
            id: FREE_PLAN_ID,
            name: 'Free Plan',
            features: [],
            tools: [],
            reviewsFeatures: freeFeatures,
            pricing: [],
            apps: 1,
            teammates: 1,
            keywords: 200,
            reviews: 100,
            isReviewAso: false,
            order: -1 * INFINITE_NUMBER,
            base: 1,
        },
    ],
};
