import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    OnboardingStepEnum,
    OnboardingYourselfStepDataType,
    YourselfStepFirstType,
    YourselfStepSecondType,
    YourselfStepThirdType,
} from 'state/onboarding/types';
import stepFourthImage from 'images/inlineStyleImages/newOnboarding/onboarding-yourself-step-fourth.svg';
import stepScheduleDemoImage from 'images/inlineStyleImages/newOnboarding/schedule-demo-step.svg';
import stepFinishImage from 'images/inlineStyleImages/newOnboarding/finish-step.svg';
import { ChoosePlanStepContainer } from 'modules/modal/onboarding/choosePlanStep/ChoosePlanStepContainer';
import { asodeskProductTourHubspot } from 'utils/Urls';
import { ButtonStateEnum } from 'components/buttons/mainButton/buttonEnum';
import { saveOnboardingState } from 'state/onboarding/controller';
import { useTogglePopup } from 'hooks/state/usePopup';
import { MODAL_NAME_ONBOARDING_YOURSELF_STEP } from 'constants/modalNameConstants';
import { useRoutesUrl } from 'hooks/useRoutesUrl';
import { useTheme } from 'hooks/state/useTheme';
import { useCompetitorsAndTrackedAppsService } from 'hooks/useCompetitorsAndTrackedAppsService';
import { useSystem } from 'hooks/state/useSystem';
import { setVisibilityOnboardingAction } from 'state/requestInfo/actions';
import { useRequestInfoService } from 'hooks/data/useRequestInfoService';
import { useAppSelector } from 'hooks/useAppSystem';

export const useOnboardingStepsData = () => {
    const dispatch = useDispatch();

    const onboardingData = useAppSelector((state) => state.onboarding);

    const { currentPlan } = useRequestInfoService();

    const [firstPlanName, secondPlanName] = currentPlan?.split(' ') || '';
    const adaptedCurrentPlan = `${firstPlanName} ${secondPlanName}`;

    const yourselfStepFirstData = onboardingData.onboardingSteps[OnboardingStepEnum.YOURSELF_STEP_FIRST];
    const yourselfStepThirdData = onboardingData.onboardingSteps[OnboardingStepEnum.YOURSELF_STEP_THIRD];
    const chooseAPlanStepData = onboardingData.onboardingSteps[OnboardingStepEnum.CHOOSE_A_PLAN_STEP];

    const { togglePopup } = useTogglePopup(MODAL_NAME_ONBOARDING_YOURSELF_STEP);

    const { getCurrentApp } = useCompetitorsAndTrackedAppsService();

    const { appId } = useSystem();

    const currentTrackedApp = appId ? getCurrentApp(appId) : undefined;

    const {
        myAppsUrl,
        asoToolsAppProfileUrl,
        reviewsBoardUrl,
        asoToolsFindAndTrackUrl,
        storesAnalyticsSearchExplorerUrl,
        connectionsHubSettingsUrl,
        keywordBoostUrl,
    } = useRoutesUrl({ currentKeyword: currentTrackedApp?.name });

    const navigate = useNavigate();

    const { isMobile } = useTheme();

    const finishStepSubTitle = chooseAPlanStepData?.leaveRequestPlanName
        ? `Our manager will be reaching out to you shortly to discuss your request for the ${chooseAPlanStepData?.leaveRequestPlanName} plan.`
        : '';

    const stepsData: OnboardingYourselfStepDataType = {
        [OnboardingStepEnum.YOURSELF_STEP_FIRST]: {
            radioLists: [
                {
                    title: 'What kind of work do you do?',
                    name: 'kindOfWork',
                    options: [
                        'Product Management',
                        'Project Management',
                        'ASO',
                        'SEO',
                        'Marketing',
                        'Customer Service',
                        'Design',
                        'User Aquisition',
                    ],
                    isOther: true,
                },
                {
                    title: 'What is your role?',
                    name: 'role',
                    options: [
                        'Business owner/Founder',
                        'C-level',
                        'Director/Head',
                        'Team Lead',
                        'Team Member',
                        'Freelancer',
                        'Student',
                    ],
                    isOther: true,
                },
            ],
            title: 'Let’s get to know you',
            titleSection: 'Tell us a bit about yourself',
            inputName: 'companyName',
            defaultDataValues: {
                ...yourselfStepFirstData,
                kindOfWork: yourselfStepFirstData['kindOfWork-other'] ? 'kindOfWork-other' : yourselfStepFirstData.kindOfWork,
                role: yourselfStepFirstData['role-other'] ? 'role-other' : yourselfStepFirstData.role,
            },
            next: {
                text: 'Next',
                onClick: async ({ kindOfWork, role, ...otherProps }: YourselfStepFirstType) => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.YOURSELF_STEP_SECOND,
                            onboardingSteps: {
                                ...onboardingData.onboardingSteps,
                                [OnboardingStepEnum.YOURSELF_STEP_FIRST]: {
                                    kindOfWork: otherProps['kindOfWork-other'] || kindOfWork,
                                    role: otherProps['role-other'] || role,
                                    ...otherProps,
                                },
                            },
                        })
                    );
                },
            },
        },
        [OnboardingStepEnum.YOURSELF_STEP_SECOND]: {
            radioLists: [
                {
                    title: 'How many people are on your team?',
                    name: 'peopleCount',
                    options: ['Only me', '2 - 5', '6 - 15', '16 - 25', '26 - 50', '51 - 100', '100+'],
                },
                {
                    title: 'Approximately how many people work at your company?',
                    name: 'approximatelyPeopleCount',
                    options: ['1 - 19', '20 - 49', '50 - 99', '100 - 250', '250 - 500', '501 - 1500', '1500+'],
                },
                {
                    title: 'Number of apps you’re planning to work with',
                    name: 'appsCount',
                    options: ['I don’t have an app', '1 - 5', '6 - 20', '20+'],
                },
            ],
            title: 'Almost done',
            titleSection: 'Tell us a bit about yourself',
            defaultDataValues: onboardingData.onboardingSteps[OnboardingStepEnum.YOURSELF_STEP_SECOND],
            next: {
                text: 'Next',
                onClick: async ({ appsCount, peopleCount, approximatelyPeopleCount }: YourselfStepSecondType) => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.YOURSELF_STEP_THIRD,
                            onboardingSteps: {
                                ...onboardingData.onboardingSteps,
                                [OnboardingStepEnum.YOURSELF_STEP_SECOND]: {
                                    appsCount,
                                    peopleCount,
                                    approximatelyPeopleCount,
                                },
                            },
                        })
                    );
                },
            },
            back: {
                text: 'Back',
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.YOURSELF_STEP_FIRST,
                        })
                    );
                },
            },
        },
        [OnboardingStepEnum.YOURSELF_STEP_THIRD]: {
            radioLists: [
                {
                    title: 'How did you hear about us?',
                    name: 'hearAboutUs',
                    options: [
                        'Search Engine (Google, Bing, Yandex, etc.)',
                        'LinkedIn',
                        'Social Media (Instagram, Facebook, etc.)',
                        'YouTube',
                        'Software Review Site',
                        'Friend/Colleague',
                    ],
                    isOther: true,
                },
            ],
            title: 'The last one',
            titleSection: 'Tell us a bit about yourself',
            defaultDataValues: {
                ...yourselfStepThirdData,
                hearAboutUs: yourselfStepThirdData['hearAboutUs-other'] ? 'hearAboutUs-other' : yourselfStepThirdData.hearAboutUs,
            },
            next: {
                text: 'Next',
                onClick: async ({ hearAboutUs, ...otherProps }: YourselfStepThirdType) => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            // Skip product tour in mobile
                            onboardingCurrentStepId: isMobile
                                ? OnboardingStepEnum.SCHEDULE_A_DEMO_STEP
                                : OnboardingStepEnum.YOURSELF_STEP_FOURTH,
                            onboardingSteps: {
                                ...onboardingData.onboardingSteps,
                                [OnboardingStepEnum.YOURSELF_STEP_THIRD]: {
                                    hearAboutUs: otherProps['hearAboutUs-other'] || hearAboutUs,
                                },
                            },
                        })
                    );
                },
            },
            back: {
                text: 'Back',
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.YOURSELF_STEP_SECOND,
                        })
                    );
                },
            },
        },
        [OnboardingStepEnum.YOURSELF_STEP_FOURTH]: {
            title: 'Welcome to Asodesk',
            titleSection: 'Beginner’s guide',
            description:
                'You can learn about the Asodesk interface, how to add your first app and all the possibilities of the platform.',
            otherControls: {
                controls: [
                    {
                        text: 'Start Beginner’s Guide',
                        onClick: async () => {
                            await dispatch(
                                saveOnboardingState({
                                    ...onboardingData,
                                    isShowTour: true,
                                    onboardingCurrentStepId: OnboardingStepEnum.SELECT_COUNTRY_STEP,
                                })
                            );

                            togglePopup();
                        },
                        className: 'mb-8',
                    },
                    {
                        text: 'Skip',
                        onClick: async () => {
                            await dispatch(
                                saveOnboardingState({
                                    ...onboardingData,
                                    onboardingCurrentStepId: OnboardingStepEnum.SCHEDULE_A_DEMO_STEP,
                                })
                            );
                        },
                        state: ButtonStateEnum.SECONDARY,
                        noBorder: true,
                    },
                ],
            },
            image: {
                src: stepFourthImage,
                alt: 'Welcome to Asodesk!',
            },
            titleClassName: 'mb-16',
        },
        [OnboardingStepEnum.SELECT_COUNTRY_STEP]: {
            title: 'Select Store Country',
            description: 'Click on a country in the dropdown.',
            titleSection: 'Beginner’s guide',
            next: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.ADD_FIRST_APP_STEP,
                        })
                    );
                },
            },
        },
        [OnboardingStepEnum.ADD_FIRST_APP_STEP]: {
            title: 'Add Your First App',
            description:
                'To add an app, type an app name or paste a URL in this field and click the Track button next to the relevant app in the dropdown list.',
            titleSection: 'Beginner’s guide',
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.SELECT_COUNTRY_STEP,
                        })
                    );
                },
            },
        },
        [OnboardingStepEnum.GLOBAL_NAVIGATION_STEP]: {
            title: 'Global Navigation',
            description: 'Here you can see all sections with tools. For now, let’s select “ASO Tools”.',
            titleSection: 'Beginner’s guide',
        },
        [OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP]: {
            title: 'Local Navigation and Tools',
            description:
                'All tools in a section are located here, divided into sub-sections. For now, let’s select Keywords and click on the “Find & Track” tool.',
            titleSection: 'Beginner’s guide',
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.GLOBAL_NAVIGATION_STEP,
                        })
                    );

                    navigate(myAppsUrl);
                },
            },
        },
        [OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP]: {
            title: 'Possibilities of Asodesk',
            description: "Now let's have a look at what you can achieve with Asodesk. Click “Next” button.",
            titleSection: 'Beginner’s guide',
            next: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.REVIEWS_RATINGS_STEP,
                        })
                    );

                    navigate(reviewsBoardUrl);
                },
            },
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.LOCAL_NAVIGATION_AND_TOOLS_STEP,
                        })
                    );

                    navigate(asoToolsAppProfileUrl);
                },
            },
        },
        [OnboardingStepEnum.REVIEWS_RATINGS_STEP]: {
            title: 'Reviews & Ratings',
            description: 'Allows you to view, analyze, export, and respond to reviews.',
            titleSection: 'Beginner’s guide',
            next: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.STORES_ANALYTICS_STEP,
                        })
                    );

                    navigate(storesAnalyticsSearchExplorerUrl);
                },
            },
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.POSSIBILITIES_OF_ASODESK_STEP,
                        })
                    );

                    navigate(asoToolsFindAndTrackUrl);
                },
            },
        },
        [OnboardingStepEnum.STORES_ANALYTICS_STEP]: {
            title: 'Stores Analytics',
            description: 'Get insights and find new opportunities on the market.',
            titleSection: 'Beginner’s guide',
            next: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.CONNECTIONS_HUB_STEP,
                        })
                    );

                    navigate(connectionsHubSettingsUrl);
                },
            },
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.REVIEWS_RATINGS_STEP,
                        })
                    );

                    navigate(reviewsBoardUrl);
                },
            },
        },
        [OnboardingStepEnum.CONNECTIONS_HUB_STEP]: {
            title: 'Connections Hub',
            description:
                'Keep your finger on the pulse of ASO changes with custom notifications, and connect Asodesk with your favorite tools.',
            titleSection: 'Beginner’s guide',
            next: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.KEYWORD_BOOST_STEP,
                        })
                    );

                    navigate(keywordBoostUrl);
                },
            },
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.STORES_ANALYTICS_STEP,
                        })
                    );

                    navigate(storesAnalyticsSearchExplorerUrl);
                },
            },
        },
        [OnboardingStepEnum.KEYWORD_BOOST_STEP]: {
            title: 'Keyword Boost',
            description: 'Reach the top in search results.',
            titleSection: 'Beginner’s guide',
            next: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.SCHEDULE_A_DEMO_STEP,
                            isShowTour: false,
                        })
                    );
                },
            },
            back: {
                onClick: async () => {
                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            onboardingCurrentStepId: OnboardingStepEnum.CONNECTIONS_HUB_STEP,
                        })
                    );

                    navigate(connectionsHubSettingsUrl);
                },
            },
        },
        [OnboardingStepEnum.SCHEDULE_A_DEMO_STEP]: {
            title: 'Need a bit more guidance?',
            titleSection: 'Beginner’s guide',
            description: 'Leave a request for an extended product demo if you still need any help.',
            otherControls: {
                direction: 'horizontal',
                controls: [
                    {
                        text: 'Schedule a Demo',
                        url: asodeskProductTourHubspot,
                        target: '_blank',
                        className: 'mr-8',
                    },
                    {
                        text: 'All good, thanks',
                        onClick: async () => {
                            await dispatch(
                                saveOnboardingState({
                                    ...onboardingData,
                                    onboardingCurrentStepId: OnboardingStepEnum.CHOOSE_A_PLAN_STEP,
                                })
                            );
                        },
                        state: ButtonStateEnum.SECONDARY,
                    },
                ],
            },
            featureLists: {
                title: 'Here are some topics we would be happy to discuss during the extended demo:',
                list: [
                    'Asodesk features',
                    'Custom solutions and plans for enterprise',
                    'Insights and recommendations for your app’s ASO',
                ],
            },
            image: {
                src: stepScheduleDemoImage,
                alt: 'Schedule a Demo',
            },
        },
        [OnboardingStepEnum.CHOOSE_A_PLAN_STEP]: {
            title: 'Find the perfect fit for you',
            titleSection: 'Сhoose a plan',
            description:
                'Choose any plan and test it out during free 14-days trial. You’ll be able to change your plan at a later stage',
            containerClassName: 'h-full overflow-hidden !pb-0',
            wrapperClassName: 'flex flex-col h-full',
            Content: <ChoosePlanStepContainer />,
        },
        [OnboardingStepEnum.FINISH_ONBOARDING_STEP]: {
            subTitle: `Your trial for the ${adaptedCurrentPlan} plan has just begun. Thanks for choosing our service! ${finishStepSubTitle}`,
            titleSection: 'Welcome on board!',
            description:
                'If you still have questions, you can contact support by clicking on the blue chat icon in the bottom right corner or request a demo via the global navigation option',
            next: {
                text: 'Start using Asodesk',
                onClick: async () => {
                    dispatch(setVisibilityOnboardingAction(false));

                    await dispatch(
                        saveOnboardingState({
                            ...onboardingData,
                            isShow: false,
                        })
                    );

                    togglePopup();
                },
            },
            image: {
                src: stepFinishImage,
                alt: 'Welcome on board!',
            },
        },
    };

    return {
        stepsData,
    };
};
